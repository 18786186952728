<template>
  <div class="container" v-if="relatedProjects && relatedProjects.length > 0">
    <h2>More <span v-html="category.name" /></h2>
    <ul>
      <li v-for="project in relatedProjects" :key="project.id">
        <router-link :to="project.link_relative">
          <img
            v-if="project.featured_media_url"
            :src="project.featured_media_url"
            v-aspect-ratio="9 / 16"
          />
          </router-link>
          <!-- <Categories
            class="categories"
            :categories="project.categories"
          /> -->
          <!-- <h3 v-html="project.title.rendered" /> -->

           <h3> <router-link class="text-link" :to="project.link_relative">
            <span v-html="project.title.rendered"/>
            </router-link>
          </h3>
          <div class="excerpt" v-html="project.excerpt.rendered" />
      </li>
    </ul>
  </div>
  <div v-else class="no-related-spacer">
  <div/>
  </div>
</template>

<script>
// import Categories from './Categories'
import Wordpress from '../mixins/Wordpress'

export default {
  mixins: [
    Wordpress
  ],
  props: {
    projectId: Number,
    category: [Object, Boolean]
  },
  data () {
    return {
      relatedProjects: null
    }
  },
  watch: {
    category () {
      this.fetchRelatedProjects()
    }
  },
  methods: {
    async fetchRelatedProjects () {
      if (this.category) {
        this.relatedProjects = await this.$wp.getPosts('project', {
          exclude: [this.projectId],
          categories: [this.category.term_id]
        })
      } else {
        this.relatedProjects = null
      }
      // console.log(this.relatedProjects)
    }
  },
  beforeMount () {
    this.fetchRelatedProjects()
  },
  components: {
    // Categories
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/variables";
@import "../styles/container";
@import "../styles/grid";
@import "../styles/text";

.container {
  @extend %container;
  @extend %mobile-container;
  margin-bottom: 220px;
  @media (max-width: 800px) {
    margin-bottom: m-grid(2);
  }
}
.no-related-spacer {
  @extend %container;
  @extend %mobile-container;
  div {
    margin-bottom: 220px;
    @media (max-width: 800px) {
      padding-bottom: calc(124px - #{m-grid(1)});
      // background: red;
      width: 100%;
      margin-bottom: 0px;
    }
  }
}

h2 {
  margin-top: 0;
  margin-bottom: 45px;
  margin-left: grid-pct(1);
  width: grid-pct(6);
  font-weight: normal;
  letter-spacing: -.01em;
  @include t3;
  @media (max-width: 800px) {
    @include m-t3;
    @include m-margin(0.5);
    margin-bottom: 32px;
  }
}

ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: grid-pct(1);
  margin-bottom: -45px;
  padding: 0;
  width: grid-pct(10);
  list-style: none;
  @media (max-width: 800px) {
    margin-bottom: 0px;
  }
}

li {
  width: 47.5%;
  @media (max-width: 800px) {
    width: 100%;
  }
}

img {
  display: block;
  margin-bottom: 24px;
  width: 100%;
  object-fit: cover;
}

.categories {
  margin-bottom: 16px;
}

@include link-text-hover-underline('.text-link', 0px, inherit, inherit);
@media (max-width: 800px) {
  @include link-text-hover-underline('.text-link', 0px, inherit, inherit);
}

h3 {
  margin-top: 0;
  margin-bottom: 16px;
  font-weight: normal;
  letter-spacing: -.01em;
  @include b1;
  @media (max-width: 800px) {
    @include m-b1;
  }
}

.excerpt {
  margin-bottom: 45px;
  font-size: 1.125rem;
  line-height: 1.5;
  @media (max-width: 800px) {
    margin-bottom: 32px;
  }
}

.excerpt /deep/ {
  p {
    margin: 0;
    @include b2;
    @media (max-width: 800px) {
      @include m-b2;
    }
  }

  p + p {
    margin-top: 24px;
  }
}
</style>
