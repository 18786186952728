<template>
<div>

  <!-- Loading. -->
  <transition name="loading">
    <div v-if="!page" class="loading">
      <Loader />
    </div>
  </transition>

  <!-- Content. -->
  <div
    v-if="page"
    class="content project"
    ref="projects"
  >
    <!-- <CloseButton
      v-show="!videoModalActive && hasPreviousRoute"
      class="close"
      :emitClick="false"
      :fallbackRoute="'/projects'"
    /> -->
    <VideoModal
      :active="videoModalActive"
      :embed_url="modalVideo.embed_url"
      :height="Number(modalVideo.height)"
      :width="Number(modalVideo.width)"
      @close="videoModalActive = false"
    />
    <ProjectContent :project="page" v-if="show"/>
    <ProjectQuotes
      v-if="show"
      :quotes="page.acf.quotes"
      :images="page.acf.images"
      :mediaItemClick="openVideo"
    />
    <ProjectCredits :credits="page.acf.credits" v-if="show && page && page.acf.credits.length > 0"/>
    <div :class="{
      'related-spacer': !(show && page && page.acf.credits.length > 0)
    }"/>
    <ProjectRelated
      :project-id="page.id"
      :category="page.acf.primary_category"
      v-if="show"
    />
  </div>

</div>
</template>

<script>
import FadeIn from '../mixins/FadeIn'
import MatchedRoute from '../mixins/MatchedRoute'
import Wordpress from '../mixins/Wordpress'
// import CloseButton from '../components/CloseButton'
import ProjectContent from '../components/ProjectContent'
import ProjectQuotes from '../components/ProjectQuotes'
import ProjectCredits from '../components/ProjectCredits'
import ProjectRelated from '../components/ProjectRelated'
import VideoModal from '../components/VideoModal'
import Loader from '../components/Loader'
import { TweenLite } from 'gsap' // note due to an import bug tween max is not avalible
import { throttle } from 'lodash'

export default {
  mixins: [
    FadeIn,
    MatchedRoute,
    Wordpress
  ],
  data () {
    return {
      show: true,
      page: null,
      videoModalActive: false,
      hasPreviousRoute: false,
      modalVideo: {
        embed_url: 'https://player.vimeo.com/video/112866269',
        height: 360,
        width: 640
      },
      eventListenerScroll: null,
      eventListenerResize: null,
      scene: null
    }
  },
  methods: {
    async fetchPage () {
      if (this.$route.query.preview) {
        this.page = await this.$wp.getPagePreviewByPath(this.$matchedRoute.pathResolved)
      } else {
        this.page = await this.$wp.getPageByPath(this.$matchedRoute.pathResolved)
      }

      // Fade Page Content In After Tick.
      this.$nextTick(() => this.fadePageContentIn())

      if (this.scene === null) {
        this.scene = this.$scrollmagic.scene({
          triggerElement: 'body',
          triggerHook: 0,
          duration: document.documentElement.scrollHeight,
          reverse: true
        })
        this.$nextTick(() => {
          this.$scrollmagic.addScene(
            this.scene.setTween(
              TweenLite.fromTo(this.$refs.projects, 30, {
                backgroundPositionY: '0%'
              }, {
                backgroundPositionY: `100%`
              })
            ) // .addIndicators() // this is a debug option for vue scroll magic
          )
          this.scene.duration(document.body.scrollHeight + window.innerHeight)
        })
      }
    },
    openVideo (videoId) {
      if (videoId === 'media-1') {
        this.modalVideo = this.page.acf.images[0].vimeo
        // console.log('media-1 triggered')
      }
      if (videoId === 'media-2') {
        this.modalVideo = this.page.acf.images[1].vimeo
        // console.log('media-2 triggered')
      }
      if (videoId === 'media-3') {
        this.modalVideo = this.page.acf.images[2].vimeo
        // console.log('media-3 triggered')
      }
      if (videoId === 'media-4') {
        this.modalVideo = this.page.acf.images[3].vimeo
        // console.log('media-4 triggered')
      }
      this.videoModalActive = true
    },
    onScroll (evt) {
      try {
        this.scene.duration(document.body.scrollHeight - window.innerHeight)
      } catch (e) {
        console.warn('@catch ~expected warning not bug~', e)
      }
    }
  },
  created () {
    this.throttleOnScroll = throttle(this.onScroll.bind(this), 300)
  },
  mounted () {
    this.eventListenerScroll = this.$nextTick(() => {
      window.addEventListener('scroll', this.throttleOnScroll, { passive: true })
    })
    this.eventListenerResize = this.$nextTick(() => {
      window.addEventListener('resize', this.throttleOnScroll, { passive: true })
    })
  },
  destroyed () {
    window.removeEventListener('scroll', this.eventListenerScroll)
    window.removeEventListener('resize', this.eventListenerResize)
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.fetchPage()
    })
  },
  components: {
    // CloseButton,
    ProjectContent,
    ProjectQuotes,
    ProjectCredits,
    ProjectRelated,
    VideoModal,
    Loader
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/container";

.project {
  background-image: url('../assets/projects/bg.svg');
  background-position: top left;
  background-attachment: fixed;
  background-repeat: no-repeat;
  overflow: hidden;
  background-size: 266.3px 2468px;
  @media (max-width: 800px) {
     background-image: none;
  }
}

.close {
  position: fixed;
  top: 48px;
  left: 50%;
  transform: translateX(-50%);
  @media (max-width: 800px) {
    top: 24px;
  }
}
.related-spacer {
  margin-bottom: 220px;
  @media (max-width: 800px) {
    margin-bottom: 124px;
  }
}
.loading {
  @include loading;
}
@include loading-page-fade;

</style>
