<template>
  <div class="container">
    <div class="row m-item-2"><!-- m item 2 -->
      <!-- <img
        class="media-1"
        v-if="images[0].image"
        :src="images[0].image.url"
        :alt="images[0].image.alt"
        v-aspect-ratio="9 / 16"
      /> -->
      <MediaItem
        :showButton="(images[0].vimeo && images[0].vimeo.embed_url.length > 0 && images[0].vimeo.height > 0 && images[0].vimeo.width > 0)"
        :buttonSide="'right'"
        v-on:click="mediaItemClick('media-1')"
        class="media-1"
        :video="images[0].video"
        :image="images[0].image"
        :aspect="9 / 16"
        :hideBackground="true"
      />
      <!-- <img
        class="media-2"
        v-if="images[1].image"
        :src="images[1].image.url"
        :alt="images[1].image.alt"
        v-aspect-ratio="330 / 550"
      /> -->
      <div class="m-media-spacer"/>
      <MediaItem
        :showButton="(images[1].vimeo && images[1].vimeo.embed_url.length > 0 && images[1].vimeo.height > 0 && images[1].vimeo.width > 0)"
        :buttonSide="'right'"
        v-on:click="mediaItemClick('media-2')"
        class="media-2"
        :video="images[1].video"
        :image="images[1].image"
        :aspect="330 / 550"
        :hideBackground="true"
      />
    </div>
    <div class="row m-item-1"> <!-- m item 1 -->
      <blockquote :class="{
        'quote-1': true,
        'empty-quote': (quotes[0].content.length === 0 && quotes[0].author.length === 0)
      }">
        <p v-if="quotes[0].content.length > 0">{{ quotes[0].content }}</p>
        <footer v-if="quotes[0].author.length > 0">{{ quotes[0].author }}</footer>
      </blockquote>

      <!-- <img
        class="media-3"
        v-if="images[2].image"
        :src="images[2].image.url"
        :alt="images[2].image.alt"
        v-aspect-ratio="9 / 16"
      /> -->

      <MediaItem
        :showButton="(images[2].vimeo && images[2].vimeo.embed_url.length > 0 && images[2].vimeo.height > 0 && images[2].vimeo.width > 0)"
        :buttonSide="'right'"
        v-on:click="mediaItemClick('media-3')"
        class="media-3"
        :video="images[2].video"
        :image="images[2].image"
        :aspect="247 / 440"
        :hideBackground="true"
      />
    </div>
    <div class="row m-item-3">
      <!-- <img
        class="media-4"
        v-if="images[3].image"
        :src="images[3].image.url"
        :alt="images[3].image.alt"
        v-aspect-ratio="370 / 660"
      /> -->

      <MediaItem
        :showButton="(images[3].vimeo && images[3].vimeo.embed_url.length > 0 && images[3].vimeo.height > 0 && images[3].vimeo.width > 0)"
        :buttonSide="'right'"
        v-on:click="mediaItemClick('media-4')"
        class="media-4"
        :video="images[3].video"
        :image="images[3].image"
        :aspect="370 / 660"
        :hideBackground="true"
      />

      <blockquote :class="{
        'quote-2': true,
        'empty-quote': (quotes[1].content.length === 0 && quotes[1].author.length === 0)
      }">
        <p v-if="quotes[1].content.length > 0">{{ quotes[1].content }}</p>
        <footer v-if="quotes[1].author.length > 0">{{ quotes[1].author }}</footer>
      </blockquote>
    </div>
  </div>
</template>

<script>
import MediaItem from './MediaItem'

export default {
  props: {
    quotes: Array,
    images: Array,
    mediaItemClick: Function
  },
  components: {
    MediaItem
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/variables";
@import "../styles/container";
@import "../styles/grid";
@import "../styles/text";

.container {
  @extend %container;
  @extend %mobile-container;
  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
  }
}

.row {
  display: flex;
  align-items: flex-start;
  @media (max-width: 800px) {
    width: 100%;
    flex-direction: column;
  }
}

img {
  object-fit: cover;
}

blockquote {
  margin: 0;
  padding: 0;
}

p {
  margin-top: 0;
  margin-bottom: 16px;
  // font-size: 1.5rem;
  // line-height: 1.5;
  @include b1;
  // line-height: px(24);
  line-height: px(36);
  @media (max-width: 800px) {
    @include m-b1;
  }
}

footer {
  font-family: $font-family-mono;
  font-size: .875rem;
  line-height: px(22.4);
  letter-spacing: .1em;
  text-transform: uppercase;
  opacity: .6;
  // &::before {
  //   margin-right: .75rem;
  //   content: '—';
  // }
  @media (max-width: 800px) {
    margin-bottom: 0px;
    font-size: px(12);
    line-height: px(15);
  }
}

/**
* mobile media & quote order
*/
.m-item-1 {
  @media (max-width: 800px) {
    order: 1;
    flex-direction: column-reverse;
  }
}

.m-item-2 {
  @media (max-width: 800px) { order: 2; }
}

.m-item-3 {
  @media (max-width: 800px) {
    order: 3;
    flex-direction: column-reverse;
  }
}

/**
* desktop order and mobile + desktop sizgin
*/
.media-1 { // 2 on mobile
  margin-left: grid-pct(1);
  width: grid-pct(4);
  @media (max-width: 800px) {
    margin-left: m-grid(1);
    width: m-grid(5);
    margin-right: 0px;
    margin-bottom: 0px;
  }
}
.m-media-spacer {
  @media (max-width: 800px) {
    margin-bottom: m-grid(1);
  }
}

.media-2 { // 3 on mobile
  margin-top: grid-pct(1);
  margin-left: grid-pct(1);
  width: grid-pct(5);
  @media (max-width: 800px) {
    margin-top: 0px;
    margin-left: 0px;
    margin-right: m-grid(1);
    width: m-grid(5);
    margin-bottom: 0px;
  }
}

.media-3 { // 1 on mobile
  margin-top: grid-pct(1);
  margin-left: grid-pct(1);
  margin-bottom: grid-pct(1);
  width: grid-pct(4);
  @media (max-width: 800px) {
    width: m-grid(5);
    margin-left: 0px;
    margin-bottom: 0px;
    margin-top: 0px;
  }
}

.media-4 {
  align-self: center;
  margin-left: grid-pct(1);
  width: grid-pct(6);
  @media (max-width: 800px) {
    @include m-margin(0);
  }
}

.quote-1 {
  margin-top: -70px;
  margin-left: grid-pct(1);
  width: grid-pct(3);
  @media (max-width: 800px) {
    margin-top: m-grid(1);
    margin-bottom: m-grid(1);
    @include m-margin(0.5);
  }
}

.quote-2 {
  align-self: center;
  margin-left: grid-pct(1);
  width: grid-pct(3);
  @media (max-width: 800px) {
    @include m-margin(0.5);
    margin-top: m-grid(1);
    margin-bottom: m-grid(1);
  }
}
.empty-quote {
  @media (max-width: 800px) {
    margin-bottom: 0px;
  }
}

blockquote {
  p {
    // @include quote-before-after;
  }
}
</style>
