<template>
  <p>
    <span
      v-for="category in categories"
      :key="category.term_id"
      v-text="category.name"
    />
  </p>
</template>

<script>
export default {
  props: {
    categories: Array
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/variables";
@import "../styles/text";

p {
  margin: 0;
  font-family: $font-family-mono;
  // font-size: .875rem;
  // line-height: 1.5;
  letter-spacing: .1em;
  text-transform: uppercase;
  opacity: .6;
  @include b3;
  line-height: px(22.4);
  @media (max-width: 800px) {
     @include m-b3;
  }
  line-height: 1.43 !important;
}

span + span::before {
  content: ' / ';
}
</style>
