<template>
  <div class="container">
    <div class="credits">
      <div
        class="text"
        v-text="credits"
      />
      <!-- </div> -->
    </div>
    <!-- <address
      v-text="credits"
      v-aspect-ratio="4 / 5"
    /> -->
  </div>
</template>

<script>
export default {
  props: {
    credits: String
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/variables";
@import "../styles/container";
@import "../styles/grid";
@import "../styles/text";

.container {
  @extend %container;
  @extend %mobile-container;
  .credits {
    display: flex;
    justify-content: center;
    align-items: center;

    max-width: d-grid(6);
    min-height: 25rem;
    border: 1px solid $red;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: d-grid(-0.5);
    margin-bottom: d-grid(1);

    @media (max-width: 800px) {
      margin-top: -10px;
      width: calc(100% + 200px);
      max-width: 200%;
      margin-left: -100px;
      margin-bottom: d-grid(1);
    }

    .text {
      white-space: pre-line;
      text-transform: uppercase;
      justify-content: center;
      display: flex;
      padding-top: 20%;
      padding-bottom: 20%;
      text-align: center;
      font-size: px(14);
      line-height: px(19);

      font-family: $font-family-mono;
      font-style: normal;
      letter-spacing: .1em;

      @media (max-width: 1100px) {
        font-size: px(12);
        line-height: px(17);
      }
      @media (max-width: 800px) {
        max-width: calc(100vw - 16vw);
        padding-top: 10%;
        padding-bottom: 10%;
      }
    }
  }
}

</style>
