<template>
  <div class="container">
    <h1
      class="with-categories-h1"
      v-html="project.title.rendered"
      v-if="project.categories && project.categories.length > 0"
    />
    <h1
      class="no-categories-h1"
      v-html="project.title.rendered"
      v-if="project.categories && project.categories.length === 0"
    />
    <Categories
      class="categories"
       :categories="project.categories"
       v-if="project.categories && project.categories.length > 0"
    />

    <iframe
      v-if="project.acf.media.media.vimeo && project.acf.media.media.vimeo.embed_url"
      :src="project.acf.media.media.vimeo.embed_url"
      v-aspect-ratio="project.acf.media.media.vimeo.height / project.acf.media.media.vimeo.width"
      frameborder="0"
      allowfullscreen
    />
    <video
      v-else-if="project.acf.media.media.video && project.acf.media.media.video.url"
      :src="project.acf.media.media.video.url"
      :poster="imageUrl"
      autoplay
      muted
      loop
      playsinline
      v-aspect-ratio="project.acf.media.media.vimeo.height / project.acf.media.media.vimeo.width"
    />
    <img
      v-else-if="imageUrl"
      :src="imageUrl"
      v-aspect-ratio="9 / 16"
    />
    
    <div class="content">
      <span v-html="project.content.rendered"/>
      <!-- <Link
        v-if="project.acf.cta"
        :link="project.acf.cta"
        class="content-call-to-action"
        v-html="'Want to see more Glencore?'"
      /> -->
    </div>
    <!-- <div class="call-to-container">
      <Link
        v-if="project.acf.cta"
        :link="project.acf.cta"
        class="call-to-action"
        v-aspect-ratio="1"
        v-html="'Want to see more Glencore?'"
      />
    </div> -->
  </div>
</template>

<script>
import Categories from './Categories'
// import Link from './Link'

export default {
  props: {
    project: Object
  },
  computed: {
    imageUrl () {
      return this.project?.featured_media_url || this.project?.acf?.media?.media?.image?.url || undefined
    }
  },
  components: {
    Categories
    // Link
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/variables";
@import "../styles/container";
@import "../styles/buttons";
@import "../styles/grid";
@import "../styles/text";

.container {
  @extend %container;
  @extend %mobile-container;
  /* margin-top: 172px; */
  /* margin-bottom: 100px; */
  @media (max-width: 800px) {
    /* margin-bottom: m-grid(1); */
    /* margin-top: 110px; */
  }
}

h1 {
  margin-top: 0;
  margin-bottom: 24px;
  margin-left: grid-pct(1);
  width: grid-pct(6);
  // font-size: 3rem;
  font-weight: normal;
  letter-spacing: -.01em;
  @include t2;
  @media (max-width: 800px) {
    @include m-margin(0.5);
    @include m-t2;
  }
}
.no-categories-h1 {
  margin-bottom: d-grid(1, #{px(12)});
  @media (max-width: 800px) {
    margin-bottom: m-grid(1, #{px(10)});
  }
}

.categories {
  margin-bottom: 110px;
  margin-left: grid-pct(6);
  width: grid-pct(5);
  @media (max-width: 800px) {
    margin-bottom: m-grid(1);
  }
}

img, video {
  display: block;
  margin-bottom: 100px;
  width: 100%;
  object-fit: cover;
}

iframe {
  margin-bottom: 100px;
  width: 100%;
  @media (max-width: 800px) {
    margin-bottom: m-grid(1, 18px);
  }
}

.content {
  margin-left: grid-pct(3.5);
  width: grid-pct(5);
  // font-size: 1.125rem;
  // line-height: 1.5;
  @include b2;
  @media (max-width: 800px) {
    @include m-margin(0.5);
    @include m-b2;
  }
}

/* .content-call-to-action {
  display: none;
  @extend %btn-outline;
  @media (max-width: 1000px) {
    margin-top: 24px;
    display: flex;
    text-align: center;
    justify-content: center;
  }
}

.call-to-container  {
  width: d-grid(2);
  margin-left: auto;
  margin-right: d-grid(1);
  @media (max-width: 1000px) {
    display: none;
  }
  .call-to-action {
    @extend %btn-circle;
    width: 100%;
    margin-top: -50%;
    margin-bottom: -50%;

  }
  .call-to-action:hover {
    cursor: pointer;
  }
} */

.content /deep/ {
  p {
    margin: 0;
    @include b2;
    @media (max-width: 800px) {
      @include m-b2;
    }
  }

  p + p {
    margin-top: 24px;
  }

  p:first-child::first-letter {
    font-size: 3rem;
    letter-spacing: -.01em;
    line-height: (1.125rem * 1.5);
    padding-right: px(3);
  }
}
</style>
